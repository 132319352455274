import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import Theme from '../../Theme';
import * as Ant from 'antd';
import {ArrowLeftShort} from '@styled-icons/bootstrap/ArrowLeftShort';
import {getCdnUrl} from '../../Utils/CdnUtil';

const pageSize = 20;
const collectionName = 'internal_news';
const pageRoute = '/news/external/';

function NewsExternal(props) {
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [searchName, setSearchName] = React.useState('');
  const [tmpSearchName, setTmpSearchName] = React.useState('');
  const [searchDate, setSearchDate] = React.useState('');
  const [tmpSearchDate, setTmpSearchDate] = React.useState('');
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const {action, id} = qs.parse(props.location.search);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {type: 'external', active: true};
      if (searchName) {
        extraQuery['title'] = {
          $regex: searchName,
        };
      }
      if (searchDate) {
        extraQuery.date = searchDate;
      }

      const resp = await JStorage.fetchDocuments(
        collectionName,
        extraQuery,
        ['-date'],
        paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging, searchName, searchDate]);

  React.useEffect(() => {
    async function fetchData() {
      if (action === 'detail') {
        AppActions.setLoading(true);
        try {
          setSelectedDocument(
            await JStorage.fetchOneDocument(collectionName, {id}),
          );
        } catch (ex) {
          console.warn('REV_DBG', ex);
        }
        AppActions.setLoading(false);
      } else {
        setSelectedDocument(null);
        fetchDocumentList();
      }
    }

    fetchData();
  }, [fetchDocumentList, action, id]);

  if (action === 'detail') {
    return (
      <Wrapper>
        <a
          href={pageRoute}
          style={{display: 'flex', alignItems: 'center', marginBottom: 20}}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(pageRoute);
          }}>
          <ArrowLeftShort size={24} />
          <div style={{fontSize: 20}}>會外新訊</div>
        </a>

        {selectedDocument && (
          <>
            <h1 style={{fontSize: 24}}>{selectedDocument.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedDocument.raw_html?.enabled
                  ? selectedDocument.raw_html.html
                  : selectedDocument.html,
              }}
            />
            {selectedDocument.file && (
              <Ant.Button
                style={{marginTop: 15}}
                type="primary"
                size="large"
                onClick={() => {
                  window.open(getCdnUrl(selectedDocument.file), '_blank');
                }}>
                下載附件
              </Ant.Button>
            )}
          </>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>會外新訊</h1>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          marginBottom: 30,
          gap: 10,
        }}>
        <Ant.Input
          value={tmpSearchName}
          onChange={(e) => setTmpSearchName(e.target.value)}
          placeholder="請輸入關鍵字"
          style={{flex: '2 0 280px', borderRadius: 100, height: 36}}
        />

        <Ant.Input
          value={tmpSearchDate}
          type="date"
          onChange={(e) => setTmpSearchDate(e.target.value)}
          style={{flex: '1 0 160px', borderRadius: 100, height: 36}}
        />

        <div style={{display: 'flex'}}>
          <Ant.Button
            type="primary"
            onClick={() => {
              setSearchName(tmpSearchName);
              setSearchDate(tmpSearchDate);
            }}>
            搜尋
          </Ant.Button>
          <Ant.Button
            type="link"
            style={{marginLeft: 6}}
            onClick={() => {
              setTmpSearchName('');
              setSearchName('');
              setTmpSearchDate('');
              setSearchDate('');
            }}>
            清除
          </Ant.Button>
        </div>
      </div>

      <Ant.Table
        columns={[
          {
            title: '名稱',
            key: 'title',
            render: (_, record) => {
              const url = `${pageRoute}?action=detail&id=${record.id}`;
              return (
                <a
                  href={record.link || url}
                  onClick={(e) => {
                    e.preventDefault();
                    if (record.link) {
                      window.open(record.link, '_blank');
                    } else {
                      AppActions.navigate(url);
                    }
                  }}
                  target="_blank"
                  rel="noreferrer">
                  {record.title}
                </a>
              );
            },
          },
          {
            title: '時間',
            key: 'date',
            width: 120,
            render: (_, record) => {
              return (
                <div>
                  <div>{record.date}</div>
                </div>
              );
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  th.ant-table-cell {
    display: none;
  }
`;

export default withPageEntry(NewsExternal);
